const THEME_8 = {
  id: "THEME_8",
  name: "Neon Vice",
  style: `
    background-color: #200331;

    .button {
      color: #ffae00;
      background-color: #511b53;
      &:hover{
        background-color: #721576;
      }
      box-shadow: rgb(168 10 174) 4px 4px 0px 0px;
      border: 3px solid rgb(115, 21, 120);

      font-size:17px;
    }
  `,
};

const THEME_7 = {
  id: "THEME_7",
  name: "Green",
  style: `
    background-color: #31B098;

    .button {
      color: #31B098;
      border-radius: 1em;
      border: none;
      background-color: #ffffff;
    }
  `,
};

const THEME_6 = {
  id: "THEME_6",
  name: "Grey",
  style: `
    background-color: #D8D8D8;

    .button {
      color: #ffffff;
      border-radius: 1em;
      border: none;
      background-color: #10173C;
    }
  `,
};

const THEME_5 = {
  id: "THEME_5",
  name: "Orange",
  style: `
    background-color: #FA9C00;

    .button {
      color: #FA9C00;
      border-radius: 1em;
      border: none;
      background-color: #FFFFFF;
    }
  `,
};

const THEME_4 = {
  id: "THEME_4",
  name: "Creamsicle",
  style: `
    background-color: #f1eee7;

    .button {
      color: rgb(79, 63, 49);
      border: 2px solid #d7c3aa;
      background-color: white;
    }
  `,
};

const Theme_3 = {
  id: "Theme_3",
  name: "Dark",
  style: `
    background: linear-gradient(180deg, #3B3B3B 0%, #000000 100%);

    .button {
      color: #1FAB35;
      border-radius: 8px;
      background-color: rgba(255,255,255,0.1);
      box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.2);
    }
  `,
};

const Theme_2 = {
  id: "Theme_2",
  name: "Gradient Pink",
  style: `
    background: linear-gradient(180deg, #FFA3EF 0%, #FFE9A3 100%);

    .button {
      border-radius: 8px;
      background: linear-gradient(180deg, #FF76F1 0%, #C923AC 100%);
      box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.2);
      color:#ffffff;
    }
  `,
};

const Theme_1 = {
  id: "Theme_1",
  name: "Blue Ocean",
  style: `
    background-color: #3A31B0;

    .button {
      border-radius: 8px;
      color: #3A31B0;
      border: 1px solid black;
      background-color: #ffffff;
    }
  `,
};

const Default = {
  id: "Default",
  name: "Default",
  style: `
    background: linear-gradient(180deg, #31B06A 0%, #ffffff 100%);
    .button {
      border: 1px solid #FFFFFF;
      border-radius: 8px;
      color: #FFFFFF;
      background-color: transparent;
    }
  `,
};

export {
  Default,
  THEME_4,
  Theme_3,
  Theme_1,
  Theme_2,
  THEME_5,
  THEME_6,
  THEME_7,
  THEME_8,
};
