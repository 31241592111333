import React, { FC, useState } from "react";
import LinkThumbnail from "../../LinkThumbnail";
import { BUTTON_TYPES } from "../BioLink";
import { buttonBorder, buttonPriorityEffect, buttonShadow } from "../../utils";
import { ButtonLinkStyleProps, ButtonProps } from "./common";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface Props extends ButtonProps {}

const StyledDropdown = styled.div<any>`
  position: relative;
  min-height: 64px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: break-spaces;
  word-break: break-all;
  display: flex;
  flex-direction: column;

  ${({ thumbnail, priorityEffect, hoverColor }) => `
          ${priorityEffect}
  
          &:hover{
            background-color:${hoverColor} !important;
          }
  
      `}

  p {
    text-align: center;
    word-break: break-word;
    line-height: 100%;
  }
`;

const Dropdown: FC<Props> = ({
  link,
  button,
  font,
  iconColor,
  isContactLink = false,
  utm,
  fireEvent,
}) => {
  const [opened, setOpen] = useState(false);

  const items = link.children ? link.children[0].row : [];

  return (
    <Stack width="100%">
      <StyledDropdown
        id={link.id}
        title={link.title}
        onClick={() => setOpen(!opened)}
        style={{
          backgroundColor: button?.custom?.color?.bgColor,
          color: button?.custom?.color?.textColor,
          border: buttonBorder(button),
          fontSize: font.size,
          boxShadow: buttonShadow(button),
        }}
        className="button"
        hoverColor={opened ? "" : button?.custom?.color?.hoverColor}
        thumbnail={link.thumbnail || null}
        priorityEffect={buttonPriorityEffect(link.priority)}
      >
        <Stack
          width="100%"
          minHeight="60px"
          justifyContent="center"
          sx={{
            position: "relative",
            borderRadius: "inherit",
            padding: link.thumbnail ? "0px 35px 0px 65px" : "0",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LinkThumbnail
            thumbnail={link.thumbnail}
            iconColor={iconColor}
            isContactLink={isContactLink}
          />
          <p style={{ margin: "auto" }}>{link.title}</p>
          <KeyboardArrowDownIcon
            style={{
              marginRight: "16px",
              transform: opened ? `rotate(180deg)` : `rotate(0deg)`,
            }}
          />
        </Stack>
      </StyledDropdown>

      {opened && (
        <Stack
          flexDirection="column"
          width="95%"
          rowGap={"12px"}
          alignSelf="center"
          mt={"12px"}
        >
          {items
            ?.filter((l) => l.isLinkValid && l.isActive)
            .map((_link) => {
              const Button = BUTTON_TYPES[_link.type];
              return (
                <Button
                  link={_link}
                  button={button}
                  font={font}
                  utm={utm}
                  fireEvent={fireEvent}
                />
              );
            })}
        </Stack>
      )}
    </Stack>
  );
};

export default Dropdown;
