import { Box, ImageListItem, styled } from "@mui/material";
import React, { FC } from "react";
import { UtmCodesModel } from "../../../../../../../../generated/graphql";
import { buttonUrl } from "../../../utils";

interface Props {
  id: string;
  thumbnail: string;
  url: string;
  title: string;
  customShadow: string;
  customBorder: string;
  animateEffect: string;
  onClick: () => void;
  utm: UtmCodesModel;
}

const GridLink = styled<any>(Box)(
  ({ $animateEffect }) => `

  ${$animateEffect};
`
);

const GridImage: FC<Props> = ({
  id,
  thumbnail,
  customShadow,
  customBorder,
  animateEffect,
  onClick,
  url,
  title,
  utm,
}) => {
  return (
    <GridLink
      id={id}
      onClick={onClick}
      component="a"
      href={buttonUrl(url, title, utm)}
      className="button"
      target="_blank"
      title={title}
      $animateEffect={animateEffect}
      sx={{
        paddingBottom: "100%",
        backgroundImage: `url(${thumbnail}) !important`,
        width: "100%",
        display: "block",
        height: 0,
        position: "relative",
        backgroundSize: "cover !important",
        backgroundPosition: "center center !important",
        boxShadow: `${customShadow} !important`,
        border: `${customBorder} !important`,
      }}
    />
  );
};

export default GridImage;
