import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React, { FC } from "react";
import { buttonBorder, buttonPriorityEffect, buttonShadow } from "../../utils";
import { ButtonLinkStyleProps, ButtonProps } from "./common";

interface Props extends ButtonProps {}

const StyledIframeLink = styled("div")<ButtonLinkStyleProps>`
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  span {
    padding: 10px;
    text-align: center;
    word-break: break-word;
  }

  ${({ $priorityEffect, $hoverColor }) => `
        ${$priorityEffect}
  
        &:hover{
          background-color:${$hoverColor} !important;
        }
      `}

  .iw {
    display: contents;
    border: none;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    & > iframe {
      border: none;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      width: 100% !important;
    }
  }
`;

const Iframe: FC<Props> = ({ link, button, font }) => {
  return (
    <StyledIframeLink
      id={link.id}
      title={link.title}
      className="button"
      style={{
        backgroundColor: button?.custom?.color?.bgColor,
        color: button?.custom?.color?.textColor,
        border: buttonBorder(button),
        fontSize: font.size,
        boxShadow: buttonShadow(button),
      }}
      $hoverColor={button?.custom?.color?.hoverColor}
      $thumbnail={link.thumbnail || null}
      $priorityEffect={buttonPriorityEffect(link.priority)}
    >
      <div
        className="iw"
        dangerouslySetInnerHTML={{
          __html: link.embededIframe || "",
        }}
      />
      <span>{link.title}</span>
    </StyledIframeLink>
  );
};

export default Iframe;
