import React, { FC, useState } from "react";
import {
  InfoBanner,
  PageSettingsInput,
  PageSettingsModel,
} from "../../../../../generated/graphql";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, ButtonBase, Stack, Typography } from "@mui/material";

interface Props {
  infoBanner: PageSettingsInput["infoBanner"];
  preview?: boolean;
}

const Banner: FC<Props> = ({ infoBanner, preview = true }) => {
  const [isOpened, setOpened] = useState(false);

  const prefix = infoBanner?.buttonUrl?.includes("http") ? "" : "//";

  return infoBanner ? (
    <Stack
      sx={{
        width: preview ? "95%" : "100%",
        position: preview ? "absolute" : "fixed",
        bottom: 0,
        borderRadius: "24px 24px 0 0",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 1,
        backgroundColor: infoBanner.colors?.background,
        padding: isOpened ? "48px" : "24px",
      }}
    >
      <Typography
        sx={{
          wordBreak: "break-word",
          textAlign: "center",
          lineHeight: "1.4rem",
          width: "75%",
        }}
      >
        {infoBanner.title}
      </Typography>
      {isOpened ? (
        <KeyboardArrowDownIcon
          onClick={() => setOpened(!isOpened)}
          sx={{
            color: "#000",
            cursor: "pointer",
            position: "absolute",
            right: "15px",
            top: "5px",
          }}
        />
      ) : (
        <KeyboardArrowUpIcon
          onClick={() => setOpened(!isOpened)}
          sx={{
            color: "#000",
            cursor: "pointer",
            position: "absolute",
            right: "15px",
            transform: "translateY(-50%)",
            top: "50%",
          }}
        />
      )}
      {isOpened && (
        <Typography
          sx={{
            wordBreak: "break-word",
            textAlign: "center",
            lineHeight: "1.4rem",
          }}
        >
          {infoBanner.content}
        </Typography>
      )}
      {isOpened && infoBanner.buttonLabel && (
        <a
          rel="noreferrer"
          href={prefix + infoBanner?.buttonUrl || ""}
          target="_blank"
          style={{
            textDecoration: "none",
          }}
        >
          <Button
            variant="primary"
            size="small"
            sx={{
              mt: 2,
              "&:hover": {
                border: "none",
                outline: "none",
                backgroundColor: infoBanner.colors?.buttonBg,
              },
              border: "none",
              color: infoBanner.colors?.buttonLabel,
              backgroundColor: infoBanner.colors?.buttonBg,
            }}
          >
            {infoBanner.buttonLabel}
          </Button>
        </a>
      )}
    </Stack>
  ) : null;
};

export default Banner;
