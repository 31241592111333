import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { TypographyProps } from "@mui/system";
import React, { FC } from "react";
import LinkThumbnail from "../../LinkThumbnail";
import {
  buttonBorder,
  buttonPriorityEffect,
  buttonShadow,
  buttonUrl,
} from "../../utils";
import { ButtonLinkStyleProps, ButtonProps } from "./common";

interface Props extends ButtonProps {}

const StyledLink = styled.a<any>`
  position: relative;
  min-height: 64px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: break-spaces;
  word-break: break-all;

  ${({ thumbnail, priorityEffect, hoverColor }) => `
          padding: ${thumbnail ? "0px 35px 0px 65px" : "0"};
          ${priorityEffect}
  
          &:hover{
            background-color:${hoverColor} !important;
          }
  
      `}

  p {
    text-align: center;
    word-break: break-word;
    line-height: 100%;
  }
`;

const Link: FC<Props> = ({
  link,
  button,
  font,
  iconColor,
  isContactLink = false,
  utm,
  fireEvent,
}) => {
  return (
    <StyledLink
      id={link.id}
      onClick={fireEvent}
      target="_blank"
      href={buttonUrl(link.url, link.title, utm)}
      style={{
        backgroundColor: button?.custom?.color?.bgColor,
        color: button?.custom?.color?.textColor,
        border: buttonBorder(button),
        fontSize: font.size,
        boxShadow: buttonShadow(button),
      }}
      title={link.title}
      className="button"
      hoverColor={button?.custom?.color?.hoverColor}
      thumbnail={link.thumbnail || null}
      priorityEffect={buttonPriorityEffect(link.priority)}
    >
      <LinkThumbnail
        thumbnail={link.thumbnail}
        iconColor={iconColor}
        isContactLink={isContactLink}
      />

      <p>{link.title}</p>
    </StyledLink>
  );
};

export default Link;
