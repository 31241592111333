import { Stack, TextField } from "@mui/material";
import React, { FC } from "react";
import {
  AppearanceModel,
  LinkModel,
  LinkPropsFragment,
  LinkType,
  PageSettingsModel,
} from "../../../../../../generated/graphql";
import SignupInput from "../SignupInput";
import SocialIconsPanel from "../SocialIconsPanel";
import PageLink from "./BioLink";

interface Props {
  pageId?: string;
  links: LinkPropsFragment[];
  font: AppearanceModel["font"];
  button: AppearanceModel["buttons"];
  settings: PageSettingsModel;
  onClick?: (e: any) => void;
  setContactPage: () => void;
  iconsColor: string;
}

const returnLinks = (links: any, emailSignup: any, smsSignup: any) => {
  let _links: any[] = [...links];
  if (emailSignup) {
    if (emailSignup.position === "top") {
      _links = ["signup", ..._links];
    }
    if (emailSignup.position === "middle") {
      _links.splice(_links.length / 2, 0, "signup" as any);
    }
    if (emailSignup.position === "bottom") {
      _links = [..._links, "signup"];
    }
  }

  if (smsSignup) {
    if (smsSignup.position === "top") {
      _links = ["sms", ..._links];
    }
    if (smsSignup.position === "middle") {
      _links.splice(_links.length / 2, 0, "sms" as any);
    }
    if (smsSignup.position === "bottom") {
      _links = [..._links, "sms"];
    }
  }

  return _links;
};

const BioLinks: FC<Props> = ({
  onClick,
  pageId,
  links,
  font,
  button,
  settings,
  setContactPage,
  iconsColor,
}) => {
  const _links = returnLinks(
    links.filter((link) => link.isLinkValid && link.isActive),
    settings?.emailSignup,
    settings?.smsSignup
  );

  return (
    <Stack
      sx={{
        rowGap: "12px",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        pb: "40px",
      }}
    >
      {_links.map((link, k) => {
        if (link === "sms") {
          return (
            <SignupInput
              key={k}
              pageId={pageId}
              font={font}
              button={button}
              type="SMS"
              colors={settings?.smsSignup?.colors}
              successMessage={settings.smsSignup?.successMessage}
              buttonText={settings.smsSignup?.buttonText}
            />
          );
        }
        if (link === "signup") {
          return (
            <SignupInput
              key={k}
              pageId={pageId}
              font={font}
              button={button}
              type="EMAIL"
              colors={settings?.emailSignup?.colors}
              successMessage={settings.emailSignup?.successMessage}
              buttonText={settings.emailSignup?.buttonText}
            />
          );
        }

        if (
          link.type === LinkType.SocialIcon &&
          link.title === "Social Badges"
        ) {
          return (
            <SocialIconsPanel
              social={link.children[0].row || []}
              fireEvent={onClick}
              color={iconsColor}
              openContanctPage={setContactPage}
            />
          );
        }

        return (
          <PageLink
            key={link.id}
            fireEvent={onClick}
            utm={settings.analytics.utmCodes}
            link={link}
            font={font}
            button={button}
          />
        );
      })}
    </Stack>
  );
};

export default BioLinks;
