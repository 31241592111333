import React from "react";
import Logo from "./Logo";
import styled from "@emotion/styled";
import FullLogo from "./../../../public/fullLogo.svg";

const JoyQrLogo = styled.a`
  display: flex;
  margin-top: auto;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Poppins;
`;

const FreeLogoBanner = () => {
  return (
    <JoyQrLogo href="https://joyqr.com/" target="_blank">
      <FullLogo width="70" viewBox="0 0 120 30" />
      Get JoyQR Free!
    </JoyQrLogo>
  );
};

export default FreeLogoBanner;
