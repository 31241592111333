import React, { FC } from "react";
import Animate from "./../../../../../../../public/link/animate_link.svg";
import Grid from "./../../../../../../../public/link/grid_link.svg";
import Redirect from "./../../../../../../../public/link/redirect_link.svg";
import Settings from "./../../../../../../../public/link/settings_link.svg";
import Schedule from "./../../../../../../../public/link/schedule_link.svg";
import Thumbnail from "./../../../../../../../public/link/thumbnail_link.svg";
import Dropdown from "./../../../../../../../public/link/dropdown_link.svg";

import NoneAnimation from "./../../../../../../../public/link/animatelink/none.svg";
import ShakeAnimation from "./../../../../../../../public/link/animatelink/shake.svg";
import PulseAnimation from "./../../../../../../../public/link/animatelink/pulse.svg";
import WobbleAnimation from "./../../../../../../../public/link/animatelink/wobble.svg";

import C1 from "./../../../../../../../public/link/grid/c1.svg";
import C2 from "./../../../../../../../public/link/grid/c2.svg";
import C3 from "./../../../../../../../public/link/grid/c3.svg";

import { BoxProps, Box } from "@mui/material";

type Icons =
  | "Animate"
  | "Grid"
  | "Redirect"
  | "Settings"
  | "Schedule"
  | "Thumbnail"
  | "Dropdown"
  | "NoneAnimation"
  | "ShakeAnimation"
  | "PulseAnimation"
  | "WobbleAnimation"
  | "C1"
  | "C2"
  | "C3";

interface Props extends BoxProps {
  type: Icons;
}

const Components = {
  Animate,
  Grid,
  Redirect,
  Settings,
  Schedule,
  Thumbnail,
  Dropdown,
  NoneAnimation,
  ShakeAnimation,
  PulseAnimation,
  WobbleAnimation,
  C1,
  C2,
  C3,
};

const FunctionIcon = React.forwardRef<any, Props>(({ type, ...props }, ref) => {
  const Icon = Components[type];
  return (
    <Box component="span" {...props} ref={ref}>
      <Icon className="functionIcon-svg" />
    </Box>
  );
});

export default FunctionIcon;
