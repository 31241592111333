import React, { FC } from "react";
import GridImage from "./GridImage";
import {
  buttonBorder,
  buttonPriorityEffect,
  buttonShadow,
  buttonUrl,
} from "../../../utils";
import { ButtonLinkStyleProps, ButtonProps } from "../common";
import GridRow from "./GridRow";

export const isUnsplash = (thumbnail: string) => {
  return thumbnail?.includes("images.unsplash.com");
};

interface Props extends ButtonProps {}

const Grid: FC<Props> = ({
  link,
  button,
  font,
  iconColor,
  isContactLink = false,
  utm,
  fireEvent,
}) => {
  const crop = (size: number) =>
    `q=80&fm=jpg&crop=entropy&fit=crop&w=${1040 / size}`;

  const items = link.children || [];

  return (
    <>
      {items.map(({ row }, key) => (
        <GridRow key={key} rowLength={row.length}>
          {row.map(({ thumbnail, ...p }: any) =>
            p.isLinkValid && p.isActive && thumbnail ? (
              <GridImage
                onClick={fireEvent}
                id={p.id}
                key={p.id}
                {...p}
                utm={utm}
                customShadow={buttonShadow(button)}
                customBorder={buttonBorder(button)}
                animateEffect={buttonPriorityEffect(p.priority)}
                thumbnail={thumbnail ? `${thumbnail}${crop(row.length)}` : ""}
              />
            ) : (
              <div />
            )
          )}
        </GridRow>
      ))}
    </>
  );
};
export default Grid;
