import {
  PageButtonModel,
  UtmCodesModel,
} from "../../../../../generated/graphql";
import { PRIORITY_EFFECTS } from "../../link-creator/components/functions/AnimateLink";

export const buttonShadow = (button: PageButtonModel | null | undefined) => {
  if (!button?.custom) {
    return ``;
  }

  if (button?.id?.includes("SMOOTH")) {
    return `4px 4px 8px 0 ${button.custom.color.shadowColor}`;
  }
  if (button?.id?.includes("SHADOW")) {
    return `4px 4px 0 0 ${button.custom.color.shadowColor}`;
  }
  return ``;
};

export const buttonBorder = (button: PageButtonModel | null | undefined) => {
  return button?.custom?.stroke
    ? `${button.custom.stroke.width}px solid ${button.custom.stroke.color}`
    : ``;
};

export const buttonUrl = (
  url: string = "",
  title: string = "",
  utm: UtmCodesModel | null | undefined
) => {
  const _url = url.includes("http") ? url : "//" + url;

  return _url + buttonUtm(title, utm);
};

export const buttonUtm = (
  title: string,
  utm: UtmCodesModel | null | undefined
) => {
  let link = ``;
  link += utm ? "?" : ``;
  link += utm?.utmMedium ? `utm_medium=${utm?.utmMedium}&` : "";
  link += utm?.utmSource ? `utm_source=${utm?.utmSource}&` : "";
  link += utm
    ? `utm_campaign=${title?.replace(/\s\s+/g, "+").replace(/\s+/g, "+")}`
    : "";
  return link;
};

export const buttonPriorityEffect = (pid: number = 0) => {
  return PRIORITY_EFFECTS.find((p) => p.id === pid)?.css || "";
};
