import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";

interface Props {
  functionName: string;
  description?: string;
  button?: any;
}

const FunctionBox: FC<PropsWithChildren<Props>> = ({
  functionName,
  children,
  button,
  description,
}) => {
  return (
    <Stack
      sx={{
        border: "1px solid  rgba(81, 81, 81, 0.2)",
        borderRadius: "25px",
        py: 3,
        px: 3,
      }}
    >
      <Stack mb={1} flexDirection="row" justifyContent="space-between">
        <Typography fontWeight={600}>{functionName}</Typography>
        {button}
      </Stack>
      {description && (
        <Typography variant="body1" mb="38px">
          {" "}
          {description}
        </Typography>
      )}

      {children}
    </Stack>
  );
};

export default FunctionBox;
