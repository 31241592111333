import React, { FC } from "react";
import {
  LinkModel,
  PageModel,
  PageSettingsModel,
  PageSocialIconsModel,
} from "../../../../../generated/graphql";
import { Icon } from "../../../../../ui";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Stack, styled } from "@mui/material";
import { Box } from "@mui/system";

interface Props {
  social: LinkModel["children"][0]["row"];
  openContanctPage?: () => void;
  color: string;
  fireEvent?: (e: any) => void;
}

const StyledPanel = styled(Box)<any>`
  .p {
    & > span {
      width: 6px;
      height: 6px;
      background-color: ${(props) => props.scolor};
      border-radius: 50 %;
      margin-left: 2px;
    }
  }
`;

const SocialIconsPanel: FC<Props> = ({
  social,
  color,
  fireEvent,
  openContanctPage,
}) => {
  return (
    <StyledPanel
      flexDirection="row"
      //    mt="2rem"
      alignItems="center"
      width={"100%"}
      display="flex"
      justifyContent="center"
      scolor={"#000000"}
    >
      {social
        .filter((l) => l.url && l.title)
        .slice(0, 4)
        .map((link, key) => {
          const IconComponent = (
            <Icon
              type={link.title as any}
              color={color || "#000000"}
              width={27}
              height={27}
            />
          );
          return IconComponent ? (
            <a
              id={link.id}
              key={key}
              onClick={fireEvent}
              href={link.url.includes("http") ? link.url : `//${link.url}`}
              target="_blank"
              style={{
                display: "flex",
                padding: "8px",
              }}
            >
              {IconComponent}
            </a>
          ) : null;
        })}
      <a
        className="p"
        rel="noreferrer"
        onClick={openContanctPage}
        style={{
          cursor: "pointer",
          display: "flex",
        }}
      >
        <MoreHorizIcon
          sx={{
            color: color || "#000000",
          }}
        />
      </a>
    </StyledPanel>
  );
};

export default SocialIconsPanel;
