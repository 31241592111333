import { Button, Box, Typography, styled } from "@mui/material";
import React, { FC, useState } from "react";
import { PageFontModel, PageModel } from "../../../../../generated/graphql";
import {
  EmailSignupInput,
  PageButtonModel,
  useCreateEmailSubscriberMutation,
  useCreateSmsSubscriberMutation,
} from "../../../../../generated/graphql";
import CloseIcon from "@mui/icons-material/Close";

interface Props extends EmailSignupInput {
  pageId?: string;
  button: PageModel["appearance"]["buttons"];
  font: PageFontModel;
  disabled?: boolean;
  onFocus?: () => void;
  type: "SMS" | "EMAIL";
}

const SuccesLabel = styled(Box)`
  height: 64px;
  width: 100%;
  border-radius: 8px;
  background-color: #1fab35;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorLabel = styled(Box)`
  height: 64px;
  width: 100%;
  border-radius: 8px;
  background-color: #f71919;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .close {
    position: absolute;
    top: 15px;
    cursor: pointer;
    right: 15px;
  }
`;

const StyledEmailInput = styled<any>(Box)`
  display: flex;
  background-color: inherit;
  margin-bottom: 10px;
  padding: 5px;
  flex-direction: column;
  font-family: inherit;

  input {
    background-color: inherit;
    border: none;
    outline: none;
    margin-bottom: 5px;
    -webkit-box-shadow: none !important;
    border: none !important;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    outline: none;
    border-radius: inherit;
    padding: 10px;
    &::placeholder {
      color: inherit;
      font-family: inherit;
      border-radius: inherit;
    }
  }

  button {
    align-self: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    border: none;
    border-radius: inherit;
  }

  ${(props: any) =>
    props.page
      ? `
      button {
        height: 50px;
      }
  `
      : `
    button {
        height: 40px;
    }
  `}
`;

const MAX: any = {
  SMS: 20,
  EMAIL: 40,
};

const SignupInput: FC<Props> = ({
  pageId,
  buttonText,
  colors,
  successMessage,
  disabled,
  onFocus,
  button,
  font,
  type,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [err, setErr] = useState("");
  const [createSubscriber, { loading, called, data }] =
    useCreateEmailSubscriberMutation({
      onError: (e) => {
        setErr(e.message);
      },
    });

  const [
    createSmsSubscriber,
    { loading: smsLoading, called: smsCalled, data: smsData },
  ] = useCreateSmsSubscriberMutation({
    onError: (e) => {
      setErr(e.message);
    },
  });

  const _onChange = (e: any) => {
    if (e.target.value.length > MAX[type]) return;
    !disabled && setInputValue(e.target.value);
  };

  const _onSubmit = () => {
    if (type === "EMAIL") {
      pageId &&
        inputValue.length > 0 &&
        createSubscriber({
          variables: {
            data: {
              email: inputValue,
              page: pageId,
            },
          },
        });
    }

    if (type === "SMS") {
      pageId &&
        inputValue.length > 0 &&
        createSmsSubscriber({
          variables: {
            data: {
              phone: inputValue,
              page: pageId,
            },
          },
        });
    }
  };

  const getShadow = () => {
    if (button?.id?.includes("SMOOTH")) {
      return `4px 4px 8px 0 ${button.custom?.color.shadowColor}`;
    }
    if (button?.id?.includes("SHADOW")) {
      return `4px 4px 0 0 ${button.custom?.color.shadowColor}`;
    }
    return ``;
  };

  const getBorder = () => {
    if (button?.custom?.stroke) {
      return `${button.custom.stroke.width}px solid ${button.custom.stroke.color}`;
    }
    return ``;
  };

  if (err) {
    const msg = err === "Bad Request Exception" ? "Email is not valid" : err;
    return (
      <ErrorLabel>
        <Typography color="#fff" fontWeight={600}>
          {msg}
        </Typography>
        <CloseIcon className="close" onClick={() => setErr("")} />
      </ErrorLabel>
    );
  }

  return (called && data) || (smsCalled && smsData) ? (
    <SuccesLabel>
      <Typography textAlign={"center"} color="#fff" fontWeight={600}>
        {successMessage || "Thanks For Signing Up!"}
      </Typography>
    </SuccesLabel>
  ) : (
    <StyledEmailInput
      page={!!pageId}
      sx={{
        bgcolor: button?.custom?.color?.bgColor,
        color: button?.custom?.color?.textColor,
        border: button?.custom && getBorder(),
        fontSize: font.size,
        boxShadow: button?.custom && getShadow(),
      }}
      className="button"
    >
      <input
        className="in"
        type={type === "EMAIL" ? "email" : "text"}
        onChange={_onChange}
        onFocus={onFocus}
        value={inputValue}
        placeholder={
          type === "EMAIL"
            ? "Enter your E-mail Address"
            : "Enter your Phone number"
        }
      />

      <Button
        onClick={_onSubmit}
        variant="primary"
        size="small"
        sx={{
          color: colors?.buttonTextColor || "#ffffff",
          backgroundColor: colors?.buttonColor || "#0F75FB",
        }}
      >
        {loading ? "Sending..." : buttonText || "Submit"}
      </Button>
    </StyledEmailInput>
  );
};

export default SignupInput;
