import { Box, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import {
  useUpdateLinkModifierMutation,
  SubscriptionPlan,
} from "../../../../../../generated/graphql";
import {
  borderInactive,
  borderActive,
  borderSelected,
} from "../../../../../reusable/content/SelectableItem";
import Paywall from "../../../../../reusable/Paywall";
import { localLinkUpdate } from "../../state";
import FunctionBox from "./FunctionBox";
import FunctionIcon from "./FunctionIcon";

export const PRIORITY_EFFECTS = [
  {
    id: 0,
    name: "none",
    icon: "NoneAnimation",
    css: ``,
  },
  {
    id: 1,
    name: "shake",
    icon: "ShakeAnimation",
    css: `
      animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1s both;
      @keyframes shake-horizontal {
          0%,
          100% {
                    transform: translateX(0);
          }
          10%,
          30%,
          50%,
          70% {
                    transform: translateX(-10px);
          }
          20%,
          40%,
          60% {
                    transform: translateX(10px);
          }
          80% {
                    transform: translateX(8px);
          }
          90% {
                    transform: translateX(-8px);
          }
        }
        `,
  },
  {
    id: 2,
    name: "wobble",
    icon: "WobbleAnimation",
    css: `
      animation: wobble-hor-bottom 1s linear 1s  both;
      @keyframes wobble-hor-bottom {
          0%,
          100% {
            transform: translateX(0%);
            transform-origin: 50% 50%;
          }
          15% {
            transform: translateX(-30px) rotate(-6deg);
          }
          30% {
            transform: translateX(15px) rotate(6deg);
          }
          45% {
            transform: translateX(-15px) rotate(-3.6deg);
          }
          60% {
            transform: translateX(9px) rotate(2.4deg);
          }
          75% {
            transform: translateX(-6px) rotate(-1.2deg);
          }
        }
        
      `,
  },
  {
    id: 3,
    name: "pulse",
    icon: "PulseAnimation",
    css: `
      animation: heartbeat 1.5s 1s  both;
      @keyframes heartbeat {
          from {
            transform: scale(1);
            transform-origin: center center;
            animation-timing-function: ease-out;
          }
          10% {
            transform: scale(0.91);
            animation-timing-function: ease-in;
          }
          17% {
            transform: scale(0.98);
            animation-timing-function: ease-out;
          }
          33% {
            transform: scale(0.87);
            animation-timing-function: ease-in;
          }
          45% {
            transform: scale(1);
            animation-timing-function: ease-out;
          }
        }
        
      `,
  },
];

interface Props {
  id: string;
  priority: number;
}

const AnimateLink: FC<Props> = ({ id, priority }) => {
  const [updateServer] = useUpdateLinkModifierMutation();

  const onSelectAnimation = (aid: number) => {
    localLinkUpdate({
      id,
      data: {
        priority: aid,
      },
    });
    updateServer({
      variables: {
        data: {
          id,
          priority: aid,
        },
      },
    });
  };

  return (
    <FunctionBox
      functionName="Animate Link"
      description="Add a little animation to link label to put more emhasis on particular link."
    >
      <Paywall minPlan={SubscriptionPlan.Plus} outline={30}>
        <Stack
          flexDirection="row"
          columnGap={4}
          rowGap={1}
          flexWrap="wrap"
          justifyContent={["center", "center", "flex-start"]}
        >
          {PRIORITY_EFFECTS.map((p, k) => (
            <Stack
              key={k}
              sx={{
                width: {
                  xs: "calc( (100% / 2)  - 32px)",
                  md: 80,
                },
              }}
            >
              <Box
                onClick={() => onSelectAnimation(p.id)}
                key={k}
                sx={{
                  /*   border: `2px ${k === 0 ? "dashed" : "solid"} ${p.id === priority ? "#0F75FB" : "black"
                       }`,*/
                  backgroundImage:
                    p.id === priority ? borderSelected : borderActive,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  p: "10px",
                  width: "100%",
                  borderRadius: "18px",
                  color: p.id === priority ? "#0F75FB" : "#04080F",
                  cursor: "pointer",
                  height: [80, 140, 80],
                }}
              >
                <FunctionIcon type={p.icon as any} />
                <Typography
                  textAlign={"center"}
                  sx={{
                    color: p.id === priority ? "#0F75FB" : "#04080F",
                    fontWeight: 700,
                    fontSize: "9px",
                  }}
                >
                  {p.name}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      </Paywall>
    </FunctionBox>
  );
};

export default AnimateLink;
