import { styled } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import { AppearanceModel } from "../../../../../generated/graphql";
import { renderGradientColor } from "../../../../../utils";
import * as FILTERS from "../themes/filters";

type TFilter = keyof typeof FILTERS;

interface Props {
  theme?: AppearanceModel["theme"];
  isPreview: boolean;
}

const StyledBGPreview = styled(Box)<any>(
  (props) => `

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    border-radius: 14px;
    ${props.$custom}
`
);

const StyledBGLive = styled(Box)<any>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${(props) => props.$custom}
`;

const BackgroundWithBgImage = (bgImage: string, bgFilter: TFilter) => {
  const isLocalImage = bgImage.includes("data:image")
    ? ""
    : "?q=80&fm=jpg&crop=entropy&fit=max&h=620";
  return `
    height: 100%;
    background-image: url(${bgImage}${isLocalImage});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    ${FILTERS[bgFilter || "Filter_1"]?.style}
    `;
};

const PageBackground: FC<Props> = ({ theme, isPreview = true }) => {
  const bgImage = theme?.custom?.bgImage;
  const bgFilter = theme?.custom?.bgFilter;
  const color = theme?.custom?.color;

  const customTheme = theme
    ? bgImage === null
      ? renderGradientColor(color as any)
      : BackgroundWithBgImage(bgImage as string, bgFilter as TFilter)
    : "";
  return isPreview ? (
    <StyledBGPreview $custom={customTheme} />
  ) : (
    <StyledBGLive $custom={customTheme} />
  );
};

export default PageBackground;
