import client from "../../../../../config/apollo";
import {
  LinkFragmentDoc,
  LinkModel,
  MeDocument,
  useMePageQuery,
} from "../../../../../generated/graphql";

export const useBioLinks = () => {
  const { data } = useMePageQuery({
    fetchPolicy: "cache-only",
  });
  return data?.me.page?.links || [];
};

interface Props {
  id: string;
  data: {
    [key in string]: any;
  };
}

export const localLinkUpdate = ({ id, data }: Props) => {
  const fn = Object.keys(data).reduce((prev, c) => {
    return {
      ...prev,
      [c]() {
        return data[c];
      },
    };
  }, {});
  client.cache.modify({
    id: `LinkModel:${id}`,
    fields: fn,
  });
};

export const getLink = (linkId: string) => {
  return client.readFragment<LinkModel>({
    id: `LinkModel:${linkId}`,
    fragment: LinkFragmentDoc,
    fragmentName: "Link",
  }) as LinkModel;
};

export const updateLinksList = (cb: any) => {
  // Read the data from our cache for this query.
  const data = client.readQuery({ query: MeDocument });
  const links = data.me.page.links;
  // Write our data back to the cache with the new comment in it
  client.writeQuery({
    query: MeDocument,
    data: {
      ...data,
      me: {
        ...data.me,
        page: {
          ...data.me.page,
          links: cb(links),
        },
      },
    },
  });
};
