import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { AppearanceModel, PageSettingsInput } from "../../../generated/graphql";
import { getQrValue } from "../../../utils";
import { createQrThumbnail } from "../dashboard/common/qrgenerator/generator";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Stack, Typography } from "@mui/material";
import { QrStateI } from "@joyqr/qrcreator/dist/qrstate";

interface Props {
  qr: QrStateI;
  contact: PageSettingsInput["contact"];
  pageName: string;
  profileImage: AppearanceModel["profileImage"];
  contactColor: string;
  closeColor: string;
  onCloseClick: () => void;
}

const QrCode = styled.div`
  border-radius: 16px;
  background-color: white;
  display: flex;
  margin-bottom: 20px;
`;

const Contact = styled.a`
  line-height: 18px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  span {
    margin-left: 5px;
  }
  &:active {
    color: inherit;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10000000;
  color: white;
  font-size: 25px;
  line-height: 25px;
  cursor: pointer;
`;

const ContactPage: FC<PropsWithChildren<Props>> = ({
  qr,
  pageName,
  contact,
  onCloseClick,
  profileImage,
  children,
  closeColor,
  contactColor,
}) => {
  const r = useRef<null | any>(null);

  const { shape, logo, eyes, module } = qr;

  useEffect(() => {
    if (r.current) {
      createQrThumbnail({
        previewElementHeight: 130,
        previewElementWidth: 130,
        previewElementId: "p",
        value: getQrValue(pageName || "", true),
        state: {
          shape: shape as any,
          frame: {
            id: -1,
            custom: null,
          },
          logo: logo,
          module: module as any,
          eyes: eyes,
          copyText: false
        },
      });

      /*  const encodedData = `data:image/svg+xml;base64,${window.btoa(
          r.current?.innerHTML
        )}`;
        r.current.remove();
        setQr(encodedData);*/
    }
  }, [pageName, shape, logo, module, eyes]);

  return (
    <>
      <CloseButton onClick={() => onCloseClick()}>
        <CancelPresentationIcon
          sx={{
            color: closeColor,
            fontSize: 50,
          }}
        />
      </CloseButton>
      <Stack pt={10} px={2} alignItems="center" width="100%" maxWidth="670px">
        {profileImage && (
          <ProfileImage src={profileImage} width={112} height={112} />
        )}
        <Typography
          sx={{
            color: contactColor,
            mt: 2,
            mb: 5,
            fontSize: 24,
          }}
        >{`@${pageName}`}</Typography>
        <QrCode>
          <div
            id={"p"}
            ref={r}
            style={{
              width: "150px",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          {/*  <img src={`${qrs}`} />*/}
        </QrCode>
        {contact?.email && (
          <Contact href={`mailto:${contact.email}`}>
            {
              <EmailIcon
                sx={{
                  color: contactColor,
                }}
              />
            }
            <Typography component="span" sx={{ color: contactColor }}>
              {contact.email}
            </Typography>
          </Contact>
        )}
        {contact?.phone && (
          <Contact href={`tel:${contact.phone}`}>
            {
              <PhoneIcon
                sx={{
                  color: contactColor,
                }}
              />
            }
            <Typography component="span" sx={{ color: contactColor }}>
              {contact.phone}
            </Typography>
          </Contact>
        )}
        <Stack width="100%" marginTop="30px" rowGap={"12px"}>
          {children}
        </Stack>
      </Stack>
    </>
  );
};

export default ContactPage;
