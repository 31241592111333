import React, { FC } from "react";
import { LinkPropsFragment } from "../../../../../generated/graphql";
import styled from "@emotion/styled";
import { Icon } from "../../../../../ui";

interface Props {
  thumbnail: LinkPropsFragment["thumbnail"];
  iconColor?: string;
  isContactLink: boolean;
}

const StyledLinkThumbnail = styled.img`
  width: 46px;
  height: 46px;
  display: block;
  object-fit: cover;
  object-position: initial;
  filter: none;
`;

const ThumbnailWr = styled.div<{ withRadius: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  left: 8px;
  display: flex;

  border-radius: inherit;
`;

const LinkThumbnail: FC<Props> = ({ thumbnail, iconColor, isContactLink }) => {
  let ThumbnailElement = null;

  if (!thumbnail || thumbnail.includes("None")) {
    return ThumbnailElement;
  }

  if (typeof thumbnail === "function") {
    const Component = thumbnail as any;
    ThumbnailElement = (
      <Component
        width="36px"
        height="50px"
        viewBox="0 0 15 15"
        fill={iconColor}
      />
    );
  } else if (thumbnail.includes("customIcon")) {
    const [d, icon, color] = thumbnail.split(":");
    const Img = (
      <Icon type={icon as any} width="36px" height="50px" color={iconColor} />
    );
    ThumbnailElement = Img;
  }

  ThumbnailElement = ThumbnailElement || (
    <StyledLinkThumbnail className="lt" src={thumbnail || ""} />
  );

  return (
    <ThumbnailWr
      withRadius={!isContactLink && !thumbnail.includes("customIcon")}
    >
      {ThumbnailElement}
    </ThumbnailWr>
  );
};

export default LinkThumbnail;
