/**
 * -----------------------------------------------------------
 * ------------------- BASE BUTTONS STYLES -------------------
 * -----------------------------------------------------------
 */

const BASE_STYLE = `
    width:100%;
    box-shadow: none;
    border:none;
    border-radius: 0px;
`;

const SHADOW = `
    border: 1px solid #000000;
    background-color: #FFFFFF;
    box-shadow: 4px 4px 0 0 #000000;
`;

const SHADOW_SMOOTH = `
    border: 1px solid #000000;
    background-color: #FFFFFF;
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.2);
`;

const ROUNDED = `
    border-radius: 0.5rem;
`;

const RECT_CURVED = `
    border-radius: 1.5rem;
`;

/**
 * -----------------------------------------------------------
 * ------------------- RECT WITHOUT RADIUS -------------------
 * -----------------------------------------------------------
 */

const RECT_GREY = {
  id: "RECT_GREY",
  style: `
    background-color: #EEEEEE;
    ${BASE_STYLE}
  `,
};

const RECT_WHITE = {
  id: "RECT_WHITE",
  style: `
    ${BASE_STYLE}
      background-color: #ffffff;
      border: 1px solid #E0E0E0;
    `,
};

const RECT_SHADOW = {
  id: "RECT_SHADOW",
  style: ` 
      ${BASE_STYLE}
        ${SHADOW}
      `,
};

const RECT_SHADOW_SMOOTH = {
  id: "RECT_SHADOW_SMOOTH",
  style: ` 
          ${BASE_STYLE}
          ${SHADOW_SMOOTH}
        `,
};

/**
 * -----------------------------------------------------------
 * ------------------- ROUNDED RECT - SMALL RADIUS -----------
 * -----------------------------------------------------------
 */

const RECT_GREY_ROUNDED = {
  id: "RECT_GREY_ROUNDED",
  style: `
      ${RECT_GREY.style}
      ${ROUNDED}
    `,
};

const RECT_WHITE_ROUNDED = {
  id: "RECT_WHITE_ROUNDED",
  style: `
        ${RECT_WHITE.style}
        ${ROUNDED}
      `,
};

const RECT_SHADOW_ROUNDED = {
  id: "RECT_SHADOW_ROUNDED",
  style: `
        ${RECT_SHADOW.style}
        ${ROUNDED}
      `,
};

const RECT_SHADOW_ROUNDED_SMOOTH = {
  id: "RECT_SHADOW_ROUNDED_SMOOTH",
  style: `
          ${RECT_SHADOW_SMOOTH.style}
          ${ROUNDED}
        `,
};

/**
 * -----------------------------------------------------------
 * ------------------- CURVED RECT - SMALL RADIUS -----------
 * -----------------------------------------------------------
 */

const RECT_GREY_CURVED = {
  id: "RECT_GREY_CURVED",
  style: `
        ${RECT_GREY.style}
        ${RECT_CURVED}
      `,
};

const RECT_WHITE_CURVED = {
  id: "RECT_WHITE_CURVED",
  style: `
          ${RECT_WHITE.style}
          ${RECT_CURVED}
        `,
};

const RECT_SHADOW_CURVED = {
  id: "RECT_SHADOW_CURVED",
  style: `
          ${RECT_SHADOW.style}
          ${RECT_CURVED}
        `,
};

const RECT_SHADOW_CURVED_SMOOTH = {
  id: "RECT_SHADOW_CURVED_SMOOTH",
  style: `
            ${RECT_SHADOW_SMOOTH.style}
            ${RECT_CURVED}
          `,
};

export const buttons = [
  RECT_GREY,
  RECT_GREY_ROUNDED,
  RECT_GREY_CURVED,
  RECT_WHITE,
  RECT_WHITE_ROUNDED,
  RECT_WHITE_CURVED,
  RECT_SHADOW,
  RECT_SHADOW_ROUNDED,
  RECT_SHADOW_CURVED,
  RECT_SHADOW_SMOOTH,
  RECT_SHADOW_ROUNDED_SMOOTH,
  RECT_SHADOW_CURVED_SMOOTH,
];

export const getButtonTheme = (t: any) =>
  buttons.find((b) => b.id === t)?.style || "";
