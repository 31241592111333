import { Box, ImageList, ImageListItem } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { LinkModel } from "../../../../../../../../generated/graphql";

interface Props {
  rowLength: number;
}

const GridRow: FC<PropsWithChildren<Props>> = ({ rowLength, children }) => {
  return children ? (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${rowLength}, 1fr)`,
        gap: "1.5rem 0.75rem",
        gridColumn: "1 / auto",
      }}
    >
      {children}
    </Box>
  ) : null;
};

export default GridRow;
