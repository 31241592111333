import { Typography } from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { AppearanceModel, PageModel } from "../../../../../generated/graphql";
import SocialIconsPanel from "./SocialIconsPanel";

interface Props
  extends Pick<AppearanceModel, "bio" | "title" | "profileImage"> {}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  z-index: 1;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  margin-top: 2.2rem;
  object-fit: cover;
`;

const PageHeader: FC<Props> = ({ bio, title, profileImage }) => {
  return (
    <Header>
      {profileImage && (
        <ProfileImage src={profileImage} width={112} height={112} />
      )}
      {title && (
        <Typography
          sx={{
            mt: "1rem",
            textAlign: "center",
            width: "90%",
            wordBreak: "break-all",
            fontFamily: title.font,
            color: title.color,
            fontSize: title.fontSize,
            lineHeight: "1.5",
          }}
        >
          {title.value}
        </Typography>
      )}
      {bio && bio.value !== "" && (
        <Typography
          sx={{
            mt: "1rem",
            whiteSpace: "break-spaces",
            textAlign: "center",
            width: "90%",
            wordBreak: "break-word",
            fontFamily: bio.font,
            lineHeight: "1.5",
            color: bio.color,
            fontSize: bio.fontSize,
          }}
        >
          {bio.value}
        </Typography>
      )}
    </Header>
  );
};

export default PageHeader;
