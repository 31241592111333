const Filter_1 = {
  id: "Filter_1",
  name: "None",
  style: ``,
};

const Filter_2 = {
  id: "Filter_2",
  name: "Light",
  style: `
    filter: brightness(178%);
  `,
};

const Filter_3 = {
  id: "Filter_3",
  name: "Dark",
  style: `
    filter: brightness(44%);
  `,
};

const Filter_4 = {
  id: "Filter_4",
  name: "Vintage",
  style: `
    filter: grayscale(87%) sepia(46%) brightness(98%) saturate(115%) contrast(102%) invert(7%);
  `,
};

const Filter_5 = {
  id: "Filter_5",
  name: "Blur",
  style: `
    filter:blur(4px);
  `,
};

const Filter_6 = {
  id: "Filter_6",
  name: "Invert",
  style: `
    filter: invert(100%);
  `,
};

const Filter_7 = {
  id: "Filter_7",
  name: "Hot",
  style: `
  filter: sepia(53%) brightness(98%) saturate(910%) contrast(221%);
  `,
};

const Filter_8 = {
  id: "Filter_8",
  name: "Retro",
  style: `
  filter: grayscale(100%) brightness(82%) saturate(0%) contrast(107%);
  `,
};

export {
  Filter_1,
  Filter_2,
  Filter_3,
  Filter_4,
  Filter_5,
  Filter_6,
  Filter_7,
  Filter_8,
};
