import React, { FC } from "react";
import { LinkType } from "../../../../../../generated/graphql";
import Link from "./link-types/Link";
import Dropdown from "./link-types/Dropdown";
import Iframe from "./link-types/Iframe";
import { ButtonProps } from "./link-types/common";
import Grid from "./link-types/grid/Grid";

interface Props extends ButtonProps {}

export const BUTTON_TYPES = {
  [LinkType.Button]: Link,
  [LinkType.DropdownButton]: Dropdown,
  [LinkType.Embeded]: Iframe,
  [LinkType.Grid]: Grid,
  [LinkType.ImageButton]: Iframe,
  [LinkType.SocialIcon]: Link,
};

const PageLink: FC<Props> = (props) => {
  const type = props.link.type || LinkType.Button;
  const Button = BUTTON_TYPES[type];
  return <Button {...props} />;
};

export default PageLink;
